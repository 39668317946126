import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from '@components/atoms/button'
import SelectedTags from '@components/selectors/tags-selector'
import AuthContext from '@contexts/auth'
import { IsValidEmail } from '@helpers/validator'
import { ArrowPathIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Utilization } from '@interfaces/banking/bank-account'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Radio,
  Tooltip,
} from '@material-tailwind/react'

import BankList from './banks'
import { usePlaid } from './usePlaid'

interface BankConnectorProps {
  open: boolean
  handleOpen: () => void
  handleConnect: () => void
  refetch: () => void
}

const BankConnector = ({ open, handleOpen, refetch }: BankConnectorProps) => {
  const { company } = useContext(AuthContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { initializePlaid, isLoading: isLoadingPlaid } = usePlaid()
  const [provider, setProvider] = useState<string>('')
  const [bankId, setBankId] = useState<string>('')
  const [bankError, setBankError] = useState<boolean>(true)
  const [emails, setEmails] = useState<string[]>([])
  const [newEmail, setNewEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')

  const onSubmit = async (data: any) => {
    bankId == '' && setBankError(true)

    if (provider === 'Plaid') {
      if (emails.length === 0) {
        setEmailError('At least one email must be provided')
        return
      }
      initializePlaid({
        slugName: company?.slug_name ?? '',
        formData: {
          entityOwnership: data.entityOwnership,
          accountUse: data.use,
          emails: emails,
        },
      })
    } else {
      handleOpen()
    }
  }

  useEffect(() => {
    if (!isLoadingPlaid && provider === 'Plaid') {
      handleOpen()
      refetch()
    }
  }, [isLoadingPlaid])

  useEffect(() => {
    setBankError(false)
  }, [bankId])

  const isLoading = isLoadingPlaid

  const handleAddEmail = () => {
    delete errors.emailAddress
    if (newEmail.trim() !== '') {
      if (IsValidEmail(newEmail)) {
        setEmails([...emails, newEmail.trim()])
        setNewEmail('')
        setEmailError('')
      } else {
        setEmailError('Email address is invalid')
      }
    }
  }

  const handleRemoveEmail = (index: number) => {
    setEmails(emails.filter((_, i) => i !== index))
  }

  const infoContent = (
    <div className="w-full text-md overflow-auto">
      <div>
        {`We need this email address to contact you in case the Plaid connection breaks.`}
      </div>
    </div>
  )

  return (
    <>
      <Dialog
        open={open}
        handler={handleOpen}
        className="overflow-auto min-w-[300px]"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader>
            <div className="flex justify-between w-full">
              <span>Add Bank Account</span>
              <XMarkIcon
                onClick={handleOpen}
                className="w-8 h-8 cursor-pointer hover:opacity-50"
              />
            </div>
          </DialogHeader>
          <DialogBody divider>
            <div className="flex flex-col gap-6 w-full mt-5">
              <BankList
                setBankId={setBankId}
                bankId={bankId}
                setProvider={setProvider}
              />
              {provider && (
                <>
                  <div>
                    <Input
                      {...register('entityOwnership', {
                        pattern: {
                          value: /^[A-Za-z]+$/i,
                          message:
                            'Invalid characters - Alphabetic characters only!',
                        },
                        disabled: isLoading,
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                      })}
                      variant="static"
                      label="Entity Ownership"
                      placeholder="Enter the entity ownership"
                      crossOrigin={undefined}
                    />
                    {errors.entityOwnership && (
                      <p className="text-red text-xs">
                        {errors.entityOwnership.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <Tooltip content={infoContent} className="z-[9999]">
                      <InformationCircleIcon className="w-8 text-primary-main cursor-help" />
                    </Tooltip>
                  </div>
                  <div className="flex gap-3 item-center justify-end items-end w-full">
                    <Input
                      {...register('emailAddress', {
                        required: {
                          value: emails.length < 1,
                          message: 'This is required',
                        },
                        disabled: isLoading,
                      })}
                      type="text"
                      value={newEmail}
                      onChange={e => setNewEmail(e.target.value)}
                      variant="static"
                      label="Email Addresses"
                      placeholder="Enter email(s) for notifications"
                      crossOrigin={undefined}
                    />
                    <Button
                      onClick={handleAddEmail}
                      type="button"
                      className="h-9 self-end transition-none focus:outline-none :hover none active:outline-none w-2/12 click:outline-none font-black px-0"
                    >
                      <span className="flex flex-row font-medium items-center">
                        <PlusIcon className="mr-1 w-5 h-5 " />
                        <div className="hidden md:inline">Add</div>
                      </span>
                    </Button>
                  </div>
                  <div>
                    {(errors.emailAddress && (
                      <p className="text-red text-xs">
                        {errors.emailAddress.message}
                      </p>
                    )) ||
                      (emailError.length > 0 && (
                        <p className="text-red text-xs">{emailError}</p>
                      ))}
                  </div>
                  <div
                    className={`relative flex flex-grow-0 shrink px-2 justify-start 
                    ${emails.length > 1 && 'overflow-x-scroll'} py-2`}
                  >
                    <SelectedTags
                      selectedTags={emails}
                      onTagRemoved={handleRemoveEmail}
                    />
                  </div>
                  <div className="grid grid-cols-2 flex">
                    <span className="col-span-2 text-sm">Use:</span>
                    <Radio
                      {...register('use', {
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                        disabled: isLoading,
                      })}
                      type="radio"
                      value={Utilization.OPERATIONS}
                      label="Operations"
                      crossOrigin={undefined}
                    />
                    <Radio
                      {...register('use', {
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                        disabled: isLoading,
                      })}
                      type="radio"
                      value={Utilization.COLLECTIONS}
                      label="Collections"
                      crossOrigin={undefined}
                    />
                    <Radio
                      {...register('use', {
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                        disabled: isLoading,
                      })}
                      type="radio"
                      value={Utilization.RESERVE}
                      label="Reserve"
                      crossOrigin={undefined}
                    />
                    <Radio
                      {...register('use', {
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                        disabled: isLoading,
                      })}
                      type="radio"
                      value={Utilization.OTHER}
                      label="Other"
                      crossOrigin={undefined}
                    />
                    {errors.use && (
                      <p className="text-red text-xs">{errors.use.message}</p>
                    )}
                  </div>
                </>
              )}
            </div>
          </DialogBody>
          <DialogFooter>
            <div className="flex flex-row gap-6">
              <Button
                type="submit"
                className="rounded-lg"
                disabled={
                  Object.keys(errors).length != 0 || bankError || isLoading
                }
                color={'primary'}
              >
                {isLoading ? (
                  <ArrowPathIcon className="w-4 h-4 mr-2 text-primary-main animate-spin" />
                ) : (
                  'Next'
                )}
              </Button>
            </div>
          </DialogFooter>
        </form>
      </Dialog>
    </>
  )
}
export default BankConnector
