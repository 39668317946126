import React from 'react'

import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'

interface BankProps {
  setBankId: any
  bankId: string
  setProvider: (provider: string) => void
}
interface AvailableBanksProps {
  /**
   * assigned id of the bank
   */
  bankId: string
  /**
   * Bank name for display purposes
   */
  bankName: string
  /**
   * website link
   */
  website: string
  /**
   * bank logo
   */
  image?: any

  provider: string
}

const availableBanks: AvailableBanksProps[] = [
  {
    bankId: '1',
    bankName: 'Connect via Plaid',
    website: 'Connect bank accounts via Plaid',
    image: '/svgs/plaid-logo.svg',
    provider: 'Plaid',
  },
]

const BankList = ({ setBankId, bankId, setProvider }: BankProps) => {
  return (
    <div className="grid grid-col-1 w-full border-2 rounded-lg p-2 overflow-y-auto max-h-50">
      {availableBanks.map((bank: AvailableBanksProps) => (
        <div
          className={
            bankId == bank.bankId
              ? 'flex flex-row w-full bg-primary-surface-2 rounded-md cursor-pointer'
              : 'flex flex-row w-full hover:bg-secondary-surface rounded-md cursor-pointer'
          }
          key={bank.bankId}
          onClick={() => {
            setBankId(bank.bankId)
            setProvider(bank.provider)
          }}
        >
          <div className="flex rounded-lg p-2 shrink-0 items-center justify-center">
            {' '}
            {bank.bankId == '0' ? (
              <ArrowUpTrayIcon className="w-6 h-6 m-4" />
            ) : (
              <img
                src={bank.image}
                alt={`${bank.bankName} Logo`}
                className="w-10 h-10 md:w-14 md:h-14 rounded-lg"
              />
            )}
          </div>
          <div className="flex flex-col p-5">
            <span className="text-sm font-bold">{bank.bankName}</span>
            {bank.bankId === '0' || bank.bankId === '1' ? (
              <span className="text-xs">{bank.website}</span>
            ) : (
              <a
                href={bank.website}
                target="_blank"
                rel="noreferrer"
                className="text-xs hover:text-blue"
              >
                {bank.website}
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default BankList
